/* NavBar Section */
.header {
	background-color: #fff;
	display: flex;
	border-bottom: 0.1px solid white;
	padding-top: 11px;
	padding-bottom: 11px;
	width: 100%;
	box-shadow: 0 2px 4px rgba(81, 107, 152, 0.16);
}

.logo > img {
	height: 60px;
	margin-left: 23px;
	transition: transform 0.1s ease-in
}
.logo > img:hover {
cursor: pointer;
transform: scale(1.04);
}

.header .lists {
	margin-right: 40px;
	margin-top: 7px;
	justify-content: space-evenly;
}
.header .lists .lists1 > Nav.Link {
	margin-right: 40px;
	color: blue;
	justify-content: space-evenly;
}

.navbar-light .navbar-nav .nav-link {
	font-weight: bold;
	color: #66023cff;
}

.navbar-light .navbar-nav .nav-link:hover {
	color: #ffc000ff;
}
/* NavBar Section End*/

/* Banner Section */

.banner {
	background: url("./Assets/bbt11.png") no-repeat center center;
	background-size: cover;
	color: white;
	object-fit: contain;
	height: 680px;
}
.banner_fadeRight {
	width: 50%;
	height: 100%;
	background-image: linear-gradient(
		270deg,
		transparent,
		rgba(37, 37, 37, 0.1),
		#111
	);
}
.banner_fadeLeft {
	width: 50%;
	height: 100%;

	background-image: linear-gradient(
		90deg,
		transparent,
		rgba(37, 37, 37, 0.1),
		#111
	);
}

/* Banner Section End*/
.content {
	position: relative;
	width: 100%;
	height: 100%;
}

/*** Card Shadow***/
.service-item {
	box-shadow: 0 2px 18px -6px rgba(0, 0, 0, 0.2);
}

.dropdown-item a {
	padding-right: 3em;
	padding-top: 1%;
	padding-bottom: 1%;
}

.product {
	transition: transform 0.1s ease-in;
}
.product:hover {
	transform: scale(1.04);
}

.navbar-nav .dropdown-menu {
	
	background-color: white;
	border: none;
	box-shadow: 0 2px 18px -6px rgba(0, 0, 0, 0.2);
}
.navbar-nav .dropdown-menu .dropdown-item a:nth-last-child() {
	color: #66023cff;
}

.navbar-nav .dropdown-menu :hover {
	background-color: rgb(255, 235, 122);
}

form p {
	padding-bottom: 10px;
	margin-top: -5px;
	color: red;
	font-size: 15px;
}

.btn-primary {
	border-color: white;
	box-shadow: none;
}
.btn-primary:active {
	/* background-color: #ffc000ff; */
	box-shadow: none;
	border-color: white;
}
.btn-primary:focus {
	color: #111;
	background-color: rgb(255, 220, 117);
	box-shadow: rgb(182, 0, 106) 0 -2px 5px 1px;
	border-color: white;
}
.primary:focus {
	box-shadow: none;
}
.btn:focus,
.btn:active {
	outline: none !important;
	box-shadow: none;
}

.contactus{
	padding-left: 2px;
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex;
	color: #66023cff ;
}
.contactus:hover{
	cursor: pointer;
	color: #ffc000ff
}


/* Footer Section */

/* Footer Section End*/
