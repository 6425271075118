@tailwind base;
@tailwind components;
@tailwind utilities;
body {
	/* font-family: 'Josefin Sans', sans-serif; */
	font-family: "Montserrat", sans-serif;
}

.service-item p {
	font-weight: 300;
}
.service-item h3 {
	font-weight: 900;
}
.title {
	font-weight: 700;
}
.product-title {
	font-weight: 600;
}
.product-brief {
   font-weight: 300;
}
